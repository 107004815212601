import React, { createContext, useState, useEffect } from "react"
import Client from "shopify-buy"

const client = Client.buildClient({
  domain: process.env.GATSBY_SHOPIFY_STORE_URL,
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN 
})

const defaultValues = {
  isCartOpen: false,
  toggleCartOpen: () => {},
  cart: [],
  addProductToCart: () => {},
  removeProductFromCart: () => {},
  checkCoupon: () => {},
  removeCoupon: () => {},
  client,
  checkout: {
    lineItems: []
  }
}

export const StoreContext = createContext(defaultValues)

export const StoreProvider = ({children}) => {

  const [checkout, setCheckout] = useState(defaultValues.checkout)
  const [isCartOpen, setCartOpen] = useState(false)
  const toggleCartOpen = () => setCartOpen(!isCartOpen)

  useEffect(() => {
    initializeCheckout()
  }, [])

  const getNewId = async (isBrowser) => {
    try {
      const checkout = await client.checkout.create()
      if (isBrowser) {
        localStorage.setItem("fmt_checkout_id", checkout.id)
      }
      return checkout
    }catch (ex) {
      console.error(ex)
    }
  }

  const initializeCheckout = async () => {
    try {
      // Check if it's a browser
      const isBrowser = typeof window !== 'undefined'
      const currentCheckoutId = isBrowser ? localStorage.getItem('fmt_checkout_id') : undefined
      let checkout

      if (currentCheckoutId) {
        // If ID exists, fetch checlout from Shopify
        checkout = await client.checkout.fetch(currentCheckoutId)

        const createdAt = new Date(checkout.createdAt)
        const today = new Date()
        const diff = Math.abs(today.getTime() - createdAt.getTime())
        const diffDays = diff / (1000 * 60 * 60 * 24)

        if (checkout.completedAt || diffDays > 7) {
          checkout = await getNewId(isBrowser)
        }
      } else {
        // If it doesn't, create a new checkout
        checkout = await getNewId(isBrowser)
      }

      setCheckout(checkout)
    } catch(ex) {
      console.error(ex)
    }
  }

  const addProductToCart = async (variantId, notes) => {
    try {
      const lineItems = []
      const item = {
        variantId,
        quantity: 1
      }

      if (notes) {
        item.customAttributes = [{
          key: "note",
          value: notes
        }]
      }

      lineItems.push(item)

      const newCheckout = await client.checkout.addLineItems(checkout.id, lineItems)
      setCheckout(newCheckout)
      return true
      // BUY NOW FUNCTIONAOLITY
      // window.open(newCheckout.webUrl, "_blank")
    } catch(ex) {
      console.error(ex)
      // If we get here it's usually a bad checkout id. clear the id to try again
      localStorage.removeItem("fmt_checkout_id")
      await initializeCheckout()
      return false
    }
  }

  const removeProductFromCart = async (lineItemId) => {
    try {
      const newCheckout = await client.checkout.removeLineItems(checkout.id, [lineItemId])
      setCheckout(newCheckout)
    } catch (ex) {
      console.error(ex)
    }
  }

  const checkCoupon = async (coupon) => {
    const newCheckout = await client.checkout.addDiscount(checkout.id, coupon)
    setCheckout(newCheckout)
  }

  const removeCoupon = async (coupon) => {
    const newCheckout = await client.checkout.removeDiscount(checkout.id, coupon)
    setCheckout(newCheckout)
  }

  return (
    <StoreContext.Provider value={{
      ...defaultValues,
      addProductToCart,
      removeProductFromCart,
      checkCoupon,
      removeCoupon,
      checkout,
      isCartOpen,
      toggleCartOpen
    }}>
      {children}
    </StoreContext.Provider>
  )
}